import { DefaultValues } from 'react-hook-form';

import { CreateEditSessionBody, MarketParams, Session } from 'common/models';

import {
  MarketParamsFormData,
  CreateEditSessionFormData,
} from './validationSchema';

export type CreateEditSessionFormDefaultValues =
  DefaultValues<CreateEditSessionFormData>;

const decimalToPercent = (
  value: number | null | undefined
): number | undefined | null =>
  value !== null && value !== undefined ? value * 100 : undefined;

const percentToDecimal = (
  value: number | null | undefined
): number | undefined | null =>
  value !== null && value !== undefined ? value / 100 : undefined;

const marketParamsTransformIn = (
  value: MarketParams | null
): MarketParamsFormData | null => {
  if (!value) return null;

  return {
    ...value,
    filterParams: {
      ...(value.filterParams ?? {}),
      priceChangeFromPrevCloseRange: {
        ...(value?.filterParams?.priceChangeFromPrevCloseRange ?? {}),
        min: decimalToPercent(
          value?.filterParams?.priceChangeFromPrevCloseRange?.min
        ),
        max: decimalToPercent(
          value?.filterParams?.priceChangeFromPrevCloseRange?.max
        ),
      },
    },
    triggerParams: {
      ...(value.triggerParams ?? {}),
      lastPeriodPriceChange: decimalToPercent(
        value?.triggerParams?.lastPeriodPriceChange
      ),
      priceChangeFromPrevClose: decimalToPercent(
        value?.triggerParams?.priceChangeFromPrevClose
      ),
    },
    openPositionParams: {
      ...(value.openPositionParams ?? {}),
      chaseDown: decimalToPercent(value?.openPositionParams?.chaseDown),
      additionalSteps: (value.openPositionParams?.additionalSteps ?? []).map(
        (item) => ({
          ...(item ?? {}),
          triggeringPriceChange: decimalToPercent(item.triggeringPriceChange),
        })
      ),
    },
    closePositionParams: {
      ...(value.closePositionParams ?? {}),
      stopLossParams: (value.closePositionParams?.stopLossParams ?? []).map(
        (item) => ({
          ...(item ?? {}),
          sizeOfClosingPositionPart: decimalToPercent(
            item.sizeOfClosingPositionPart
          ),
        })
      ),
      takeProfitParams: (value.closePositionParams?.takeProfitParams ?? []).map(
        (item) => ({
          ...(item ?? {}),
          triggeringPriceChange: decimalToPercent(item.triggeringPriceChange),
          sizeOfClosingPositionPart: decimalToPercent(
            item.sizeOfClosingPositionPart
          ),
        })
      ),
      timeBasedParams: (value.closePositionParams?.timeBasedParams ?? []).map(
        (item) => ({
          ...(item ?? {}),
          sizeOfClosingPositionPart: decimalToPercent(
            item.sizeOfClosingPositionPart
          ),
        })
      ),
    },
  } as MarketParamsFormData;
};

const marketParamsTransformOut = (
  value: MarketParamsFormData | undefined | null
): MarketParams | undefined | null => {
  if (!value) return null;

  const {
    active,
    filterParams,
    triggerParams,
    openPositionParams,
    closePositionParams,
  } = value;

  if (!active) {
    return {
      active: false,
    };
  }

  const { priceChangeFromPrevCloseRange } = filterParams;
  const { lastPeriodPriceChange, priceChangeFromPrevClose } =
    triggerParams ?? {};
  const { chaseDown, additionalSteps } = openPositionParams;
  const { stopLossParams, takeProfitParams, timeBasedParams } =
    closePositionParams;

  return {
    ...value,
    filterParams: {
      ...(filterParams ?? {}),
      priceChangeFromPrevCloseRange:
        priceChangeFromPrevCloseRange.min && priceChangeFromPrevCloseRange.max
          ? {
              min: percentToDecimal(priceChangeFromPrevCloseRange?.min),
              max: percentToDecimal(priceChangeFromPrevCloseRange?.max),
            }
          : priceChangeFromPrevCloseRange,
    },
    triggerParams: {
      ...(triggerParams ?? {}),
      lastPeriodPriceChange: percentToDecimal(lastPeriodPriceChange),
      priceChangeFromPrevClose: percentToDecimal(priceChangeFromPrevClose),
    },
    openPositionParams: {
      ...(openPositionParams ?? {}),
      chaseDown: percentToDecimal(chaseDown),
      additionalSteps: additionalSteps
        ? additionalSteps.map((item) => ({
            ...(item ?? {}),
            triggeringPriceChange: percentToDecimal(item.triggeringPriceChange),
          }))
        : additionalSteps,
    },
    closePositionParams: {
      ...(closePositionParams ?? {}),
      stopLossParams: stopLossParams
        ? stopLossParams.map((item) => ({
            ...(item ?? {}),
            sizeOfClosingPositionPart: percentToDecimal(
              item.sizeOfClosingPositionPart
            ),
          }))
        : stopLossParams,
      takeProfitParams: takeProfitParams
        ? takeProfitParams.map((item) => ({
            ...(item ?? {}),
            triggeringPriceChange: percentToDecimal(item.triggeringPriceChange),
            sizeOfClosingPositionPart: percentToDecimal(
              item.sizeOfClosingPositionPart
            ),
          }))
        : takeProfitParams,
      timeBasedParams: timeBasedParams
        ? timeBasedParams.map((item) => ({
            ...(item ?? {}),
            sizeOfClosingPositionPart: percentToDecimal(
              item.sizeOfClosingPositionPart
            ),
          }))
        : timeBasedParams,
    },
  } as MarketParams;
};

export const transformIn = (
  values: Session | null | undefined
): CreateEditSessionFormDefaultValues | undefined => {
  if (!values) return undefined;

  const { sessionName, rthParams, phParams, ahParams, blacklist } = values;

  return {
    sessionName: sessionName ?? '',
    rthParams: marketParamsTransformIn(rthParams),
    phParams: marketParamsTransformIn(phParams),
    ahParams: marketParamsTransformIn(ahParams),
    blacklist: blacklist ?? [],
  } as CreateEditSessionFormDefaultValues;
};

export const transformOut = (
  values: CreateEditSessionFormDefaultValues
): CreateEditSessionBody => {
  const { rthParams, phParams, ahParams } = values;

  return {
    ...values,
    rthParams: marketParamsTransformOut(
      rthParams as MarketParamsFormData | undefined
    ),
    phParams: marketParamsTransformOut(
      phParams as MarketParamsFormData | undefined
    ),
    ahParams: marketParamsTransformOut(
      ahParams as MarketParamsFormData | undefined
    ),
  } as CreateEditSessionBody;
};
