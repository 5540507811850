import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { generateTimeOptions } from 'common/utils';
import { DurationInput, SelectInput, TextInput } from 'core/components/form';

import OrderTypeInputs from './OrderTypeInputs';
import { CommonInputsProps } from './types';

const TimeBasedInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const timeOptions = generateTimeOptions(5, 570, 960, {
    start: 950,
    interval: 1,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${basename}.closePositionParams.timeBasedParams`,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4">
          <div className="mb-4 flex flex-row items-center gap-2">
            <button
              onClick={() => remove(index)}
              disabled={disabled}
              className="h-full bg-red-500 p-2"
            >
              <XMarkIcon className="size-5" />
            </button>
            <p className="text-sm">
              {t(
                'pages.createSession.closePositionParams.form.timeBasedParams.title',
                { number: index + 1 }
              )}
            </p>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex w-full gap-3">
              <SelectInput
                control={control}
                name={`${basename}.closePositionParams.timeBasedParams.${index}.triggeringTime`}
                componentProps={{
                  disabled,
                  label: t(
                    'pages.createSession.closePositionParams.form.timeBasedParams.triggeringTime.label'
                  ),
                  isMulti: false,
                  options: timeOptions,
                }}
              />
              <TextInput
                control={control}
                name={`${basename}.closePositionParams.timeBasedParams.${index}.sizeOfClosingPositionPart`}
                componentProps={{
                  disabled,
                  type: 'number',
                  label: t(
                    'pages.createSession.closePositionParams.form.timeBasedParams.sizeOfClosingPositionPart.label'
                  ),
                  suffix: '%',
                }}
              />
            </div>
            <OrderTypeInputs
              {...{
                control,
                disabled,
                orderTypeName: `${basename}.closePositionParams.timeBasedParams.${index}.orderType`,
                orderPriceTypeName: `${basename}.closePositionParams.timeBasedParams.${index}.orderPriceType`,
              }}
            />
            <TextInput
              control={control}
              name={`${basename}.closePositionParams.timeBasedParams.${index}.entriesCount`}
              componentProps={{
                disabled,
                type: 'number',
                label: t(
                  'pages.createSession.closePositionParams.form.timeBasedParams.entriesCount.label'
                ),
              }}
            />
            <DurationInput
              control={control}
              name={`${basename}.closePositionParams.timeBasedParams.${index}.openEntryInterval`}
              componentProps={{
                disabled,
                label: t(
                  'pages.createSession.closePositionParams.form.timeBasedParams.openEntryInterval.label'
                ),
              }}
            />
            <DurationInput
              control={control}
              name={`${basename}.closePositionParams.timeBasedParams.${index}.orderRefreshInterval`}
              componentProps={{
                disabled,
                label: t(
                  'pages.createSession.closePositionParams.form.timeBasedParams.orderRefreshInterval.label'
                ),
              }}
            />
            <DurationInput
              control={control}
              name={`${basename}.closePositionParams.timeBasedParams.${index}.openEntryLimit`}
              componentProps={{
                disabled,
                label: t(
                  'pages.createSession.closePositionParams.form.timeBasedParams.openEntryLimit.label'
                ),
              }}
            />
            <DurationInput
              control={control}
              name={`${basename}.closePositionParams.timeBasedParams.${index}.exitDelay`}
              componentProps={{
                disabled,
                label: t(
                  'pages.createSession.closePositionParams.form.timeBasedParams.exitDelay.label'
                ),
              }}
            />
          </div>
        </div>
      ))}
      {fields.length < 3 && (
        <button
          type="button"
          onClick={() =>
            append({
              triggeringTime: undefined,
              sizeOfClosingPositionPart: undefined,
              orderType: undefined,
              orderPriceType: undefined,
              entriesCount: undefined,
              openEntryInterval: undefined,
              orderRefreshInterval: undefined,
              openEntryLimit: undefined,
              exitDelay: undefined,
            })
          }
          className="outlined-button-s mt-2 w-full"
          disabled={disabled}
        >
          {t(
            'pages.createSession.closePositionParams.form.timeBasedParams.add'
          )}
        </button>
      )}
    </div>
  );
};

export default TimeBasedInputs;
