import React, { FC, Fragment } from 'react';

import ClosingParameters from './ClosePositionInputs';
import FilterInputs from './FilterInputs';
import OpenPositionInputs from './OpenPositionInputs';
import TriggerInputs from './TriggerInputs';
import { CommonInputsProps } from './types';

const CommonInputs: FC<CommonInputsProps> = (props) => {
  return (
    <Fragment>
      <FilterInputs {...props} />
      <TriggerInputs {...props} />
      <OpenPositionInputs {...props} />
      <ClosingParameters {...props} />
    </Fragment>
  );
};

export default CommonInputs;
