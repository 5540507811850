import React from 'react';
import { useTranslation } from 'react-i18next';

import Wrapper from '../Wrapper';

const Footer = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <Wrapper
      as="footer"
      className="!py-6"
      internalClassName="flex flex-col items-center gap-3 text-center"
    >
      <p className="caption">{t('components.footer.copyRights', { year })}</p>
    </Wrapper>
  );
};

export default Footer;
