import { Field, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React from 'react';
import { Range as EpRange } from 'react-range';
import { IRenderThumbParams, IRenderTrackParams } from 'react-range/lib/types';

import { InputProps } from './types';

export type RangeProps<T = number> = InputProps & {
  min: T;
  max: T;
  step: number;
  value: T[];
  disabled?: boolean;
  onChange: (value: T[]) => void;
  parseValue: (value: T) => number;
  formatValue: (value: number) => T;
  renderLabel?: (value: number) => string | number;
};

function Range<T = number>({
  label,
  className,
  error,
  step,
  min,
  max,
  value,
  disabled,
  onChange,
  renderLabel = (value) => value,
  parseValue,
  formatValue,
}: RangeProps<T>) {
  const parsedMin = parseValue(min);
  const parsedMax = parseValue(max);
  const parsedValues =
    !!value && value.length > 0
      ? value.map(parseValue)
      : [parsedMin, parsedMax];
  let values: number[];

  if (parsedValues[0] < parsedMin || parsedValues[1] > parsedMax) {
    values = [parsedMin, parsedMax];
  } else {
    values = parsedValues;
  }

  const handleChange = (newValues: number[]) => {
    const formattedValues = newValues.map(formatValue);
    onChange(formattedValues);
  };

  return (
    <Field
      className={clsx('flex w-full flex-col', className)}
      disabled={disabled}
    >
      {label && <Label className="caption mb-4 ml-1 text-xs">{label}</Label>}
      <EpRange
        step={step}
        min={parsedMin}
        max={parsedMax}
        values={values}
        disabled={disabled}
        onChange={handleChange}
        renderTrack={({ props, children }: IRenderTrackParams) => {
          const leftPercentage =
            ((values[0] - parsedMin) / (parsedMax - parsedMin)) * 100;
          const widthPercentage =
            ((values[1] - values[0]) / (parsedMax - parsedMin)) * 100;

          return (
            <div {...props} className="relative h-[6px] w-full">
              <div
                className={clsx(
                  'absolute h-full rounded',
                  disabled ? 'bg-slate-300' : 'bg-slate-200'
                )}
                style={{ width: `${leftPercentage}%` }}
              />
              <div
                className={clsx(
                  'absolute h-full rounded',
                  disabled ? 'bg-primary-200' : 'bg-primary'
                )}
                style={{
                  left: `${leftPercentage}%`,
                  width: `${widthPercentage}%`,
                }}
              />
              <div
                className={clsx(
                  'absolute h-full rounded',
                  disabled ? 'bg-slate-300' : 'bg-slate-200'
                )}
                style={{
                  left: `${leftPercentage + widthPercentage}%`,
                  right: 0,
                }}
              />
              {children}
            </div>
          );
        }}
        renderThumb={({ props: { key, ...props } }: IRenderThumbParams) => (
          <div
            key={key}
            {...props}
            className={clsx(
              'relative size-5 rounded-full',
              disabled ? 'bg-primary-200' : 'bg-primary'
            )}
          />
        )}
      />
      <div className="mt-3 flex flex-row justify-between">
        <p className="caption text-xs">{renderLabel(values[0])}</p>
        <p className="caption text-xs">{renderLabel(values[1])}</p>
      </div>
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </Field>
  );
}

export default Range;
