import { t } from 'i18next';
import React from 'react';
import { useController, FieldValues } from 'react-hook-form';
import * as yup from 'yup';

import { DefaultFormInputProps } from './types';
import { CodeInput as OtpCodeInput, CodeInputProps } from '../uikit';

export const createPinCodeSchema = ({
  size,
  name,
}: {
  size?: number;
  name?: string;
} = {}) =>
  yup
    .string()
    .required(
      t('errors.validation.isRequired', {
        name: name ?? t('pages.verifyPhone.fields.otpCode'),
      })
    )
    .min(size ?? 6)
    .max(size ?? 6);

const CodeInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<
  FormValues,
  Omit<CodeInputProps, 'renderInput' | 'onChange' | 'value'>
>) => {
  const { field } = useController(input);

  return <OtpCodeInput {...{ ...field, ...componentProps }} />;
};

export default CodeInput;
