import { Dialog, DialogBackdrop } from '@headlessui/react';
import React, { FC, useState } from 'react';

import { StopBehavior } from 'common/models';
import { Callback } from 'common/types';

import ChooseStopBehaviorContent from './ChooseStopBehaviorDialogContent';
import StopSessionContent from './StopSessionDialogContent';

export type StopSessionDialogProps = {
  isOpen: boolean;
  onClose: Callback;
};

const StopSessionDialog: FC<StopSessionDialogProps> = ({ isOpen, onClose }) => {
  const [stopBehavior, setStopBehavior] = useState<StopBehavior>();

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {stopBehavior === undefined ? (
          <ChooseStopBehaviorContent {...{ onClose, setStopBehavior }} />
        ) : (
          <StopSessionContent {...{ onClose, stopBehavior, setStopBehavior }} />
        )}
      </div>
    </Dialog>
  );
};

export default StopSessionDialog;
