import { t } from 'i18next';
import React from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import * as yup from 'yup';

import { MinMaxValue } from './MinMaxInput';
import { Range, RangeProps } from '../uikit';

export function createRangeInputSchema({
  name,
  required,
}: {
  name: string;
  required: boolean;
}) {
  return yup
    .object({
      min: yup
        .string()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value
        ),
      max: yup
        .string()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value
        ),
    })
    .test(
      'both-or-none',
      t('errors.validation.range.mustIncludeBoth', { name }),
      (value) => {
        if (!value) return true;
        const { min, max } = value;
        return (min == null && max == null) || (min != null && max != null);
      }
    )
    .when([], {
      is: () => required,
      then: (schema) =>
        schema.required(t('errors.validation.isRequired', { name })),
      otherwise: (schema) => schema.notRequired(),
    });
}

export type RangeInputProps<
  FormValues extends FieldValues,
  ValueT = string,
> = UseControllerProps<FormValues> & {
  componentProps: Omit<RangeProps<ValueT>, 'value' | 'error' | 'onChange'>;
};

const RangeInput = <FormValues extends FieldValues, ValueT = number>({
  componentProps,
  ...input
}: RangeInputProps<FormValues, ValueT>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;
  const { value, onChange } = field;

  const handleChange = (updatedValue: ValueT[]) => {
    const [min, max] = updatedValue;
    onChange({ min, max } as MinMaxValue<ValueT>);
  };

  const adaptedValue: ValueT[] = [value?.min || null, value?.max || null];

  return (
    <Range
      {...componentProps}
      error={error}
      value={adaptedValue}
      onChange={handleChange}
    />
  );
};

export default RangeInput;
