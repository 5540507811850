import React from 'react';
import { useController, FieldValues } from 'react-hook-form';

import { DefaultFormInputProps } from './types';
import { Checkbox, CheckboxProps } from '../uikit';

const CheckboxInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, CheckboxProps>) => {
  const { field } = useController(input);
  const { value, onChange } = field;

  return <Checkbox {...componentProps} checked={value} onChange={onChange} />;
};

export default CheckboxInput;
