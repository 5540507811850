import { InformationCircleIcon } from '@heroicons/react/24/outline';
import React, { FC, useEffect, useRef, useState } from 'react';

export type TooltipProps = {
  text: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
};

const Tooltip: FC<TooltipProps> = ({ text, position = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (tooltipRect.right > viewportWidth) {
        setAdjustedPosition('left');
      } else if (tooltipRect.left < 0) {
        setAdjustedPosition('right');
      } else if (tooltipRect.top < 0) {
        setAdjustedPosition('bottom');
      } else if (tooltipRect.bottom > viewportHeight) {
        setAdjustedPosition('top');
      } else {
        setAdjustedPosition(position);
      }
    }
  }, [position]);

  const getPositionClasses = () => {
    switch (adjustedPosition) {
      case 'top':
        return 'bottom-full left-1/2 transform -translate-x-1/2 mb-2';
      case 'bottom':
        return 'top-full left-1/2 transform -translate-x-1/2 mt-2';
      case 'left':
        return 'right-full top-1/2 transform -translate-y-1/2 mr-2';
      case 'right':
        return 'left-full top-1/2 transform -translate-y-1/2 ml-2';
      default:
        return '';
    }
  };

  return (
    <div className="relative flex items-center">
      <InformationCircleIcon
        className="h-6 w-6 cursor-pointer text-gray-600"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      />
      {isVisible && (
        <div
          ref={tooltipRef}
          className={`caption absolute z-10 inline-block w-max max-w-xs rounded bg-slate-50 px-4 py-2 shadow-lg ${getPositionClasses()}`}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
