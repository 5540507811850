import { Option } from 'common/types';

const generateTimeOptions = (
  interval: number = 30,
  startMinutes: number = 0,
  endMinutes: number = 1440,
  lastMinutesInterval: { start: number; interval: number } | null = null
): Option[] => {
  const options: Option[] = [];

  if (startMinutes < 0 || endMinutes > 1440 || startMinutes > endMinutes) {
    throw new Error(
      'Invalid time range. Ensure 0 <= startMinutes <= endMinutes <= 1440.'
    );
  }

  for (
    let currentMinutes = startMinutes;
    currentMinutes <= endMinutes;
    currentMinutes += interval
  ) {
    if (
      lastMinutesInterval &&
      currentMinutes >= lastMinutesInterval.start &&
      currentMinutes < endMinutes
    ) {
      interval = lastMinutesInterval.interval;
    }

    const hour = Math.floor(currentMinutes / 60);
    const minutes = currentMinutes % 60;
    const suffix = hour < 12 ? 'AM' : 'PM';
    const hour12 = hour % 12 || 12;
    const time = `${String(hour12).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${suffix}`;
    options.push({ value: time, label: time });
  }

  return options;
};

export default generateTimeOptions;
