import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from 'core/components/common';

import { ChangePinForm } from './components';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h2 className="mb-6">{t('pages.settings.title')}</h2>
      <ChangePinForm />
    </Wrapper>
  );
};

export default Settings;
