import { t } from 'i18next';
import * as yup from 'yup';

import { createOtpCodeSchema } from 'core/components/form';

export const changePinCodeSchema = yup
  .object({
    prevCode: createOtpCodeSchema({
      size: 4,
      name: t('pages.settings.changePinForm.fields.prevPin.name'),
    }),
    newCode: createOtpCodeSchema({
      size: 4,
      name: t('pages.settings.changePinForm.fields.newPin.name'),
    }),
  })
  .required();

export type ChangePinCodeFormData = yup.InferType<typeof changePinCodeSchema>;
