import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';

import AfterhoursParameters from './AfterhoursParameters';
import PrehoursParameters from './PrehoursParameters';
import RthParameters from './RthParameters';
import { CreateEditSessionFormData } from '../validationSchema';

export type MarketParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: {
    rthForm?: boolean;
    phForm?: boolean;
    ahForm?: boolean;
  };
};

const MarketParameters: FC<MarketParametersProps> = ({ control, disabled }) => {
  return (
    <div className="flex flex-col justify-between gap-6 md:flex-row">
      <PrehoursParameters control={control} disabled={disabled?.phForm} />
      <RthParameters control={control} disabled={disabled?.rthForm} />
      <AfterhoursParameters control={control} disabled={disabled?.ahForm} />
    </div>
  );
};

export default MarketParameters;
