import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionType } from 'common/models';
import { Option } from 'common/types';
import { Select } from 'core/components/uikit';

export type ActionFilterOption = Option<ActionType | null>;

export type TypeSelectInputProps = {
  type: string | undefined;
  onChange: (value: ActionType | undefined) => void;
};

const TypeSelectInput: FC<TypeSelectInputProps> = ({ type, onChange }) => {
  const { t } = useTranslation();

  const filterOptions = useMemo<ActionFilterOption[]>(
    () => [
      {
        value: null,
        label: t('components.lastActionsTable.filters.status.all'),
      },
      {
        value: 'STATUS',
        label: t('components.lastActionsTable.filters.status.status'),
      },
      {
        value: 'ALERT',
        label: t('components.lastActionsTable.filters.status.alerts'),
      },
      {
        value: 'TRIGGER',
        label: t('components.lastActionsTable.filters.status.trigger'),
      },
      {
        value: 'TRADE',
        label: t('components.lastActionsTable.filters.status.trades'),
      },
      {
        value: 'ERROR',
        label: t('components.lastActionsTable.filters.status.errors'),
      },
    ],
    [t]
  );

  const value =
    filterOptions.find((item) => item.value === type) ?? filterOptions[0];

  return (
    <Select
      value={value}
      className="max-w-52"
      label={t('components.lastActionsTable.filters.status.label')}
      onChange={(newValue) =>
        onChange((newValue as ActionFilterOption).value ?? undefined)
      }
      options={filterOptions}
    />
  );
};

export default TypeSelectInput;
