import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { CreateEditSessionBody, Session, StopSessionBody } from 'common/models';
import { sessionsRepository } from 'common/repositories';
import { useSessionsStore } from 'common/stores';

type State = {
  isLoading: boolean;
  session: Session | null;
};

type Actions = {
  fetch: (
    params: Parameters<typeof sessionsRepository.getSessionById>[0]
  ) => Promise<Session | null>;
  startSession: () => Promise<void>;
  setLoading: (value: boolean) => void;
  deleteSession: () => Promise<boolean>;
  setSession: (sessions: Session) => void;
  editSession: (body: CreateEditSessionBody) => Promise<boolean>;
  stopSession: (
    body?: Pick<StopSessionBody, 'stopBehavior'>
  ) => Promise<{ success: boolean; hasOpenedPositions: boolean }>;
};

export type SessionStore = State & Actions;

const defaultState: State = {
  isLoading: false,
  session: null,
};

const useEditSessionStore = create<SessionStore>()(
  immer((set, get) => ({
    ...defaultState,

    setLoading: (value) =>
      set((state) => {
        state.isLoading = value;
      }),

    setSession: (value) =>
      set((state) => {
        state.session = value;
      }),

    fetch: async ({ sessionId }) => {
      const { setLoading, setSession } = get();

      setLoading(true);

      let data: Session | null | undefined;
      const sessions = useSessionsStore.getState().sessions ?? [];
      data = sessions.find((item) => item.sessionId === sessionId);

      if (!data) {
        const res = await sessionsRepository.getSessionById({
          sessionId,
        });
        data = res.data;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      setLoading(false);

      if (!data) {
        return null;
      }

      setSession(data);

      return data;
    },

    editSession: async (body) => {
      const { session, setSession } = get();
      const { editSession } = useSessionsStore.getState();

      if (!session) {
        return false;
      }

      const editedSession = await editSession({
        body,
        sessionId: session.sessionId ?? '',
      });

      if (!editedSession) {
        return false;
      }

      setSession(editedSession);

      return true;
    },

    stopSession: async ({ stopBehavior } = {}) => {
      const { session, setSession } = get();
      const { stopSession } = useSessionsStore.getState();

      if (!session) {
        return {
          success: false,
          hasOpenedPositions: false,
        };
      }
      try {
        const stoppedSession = await stopSession({
          sessionId: session.sessionId ?? '',
          stopBehavior,
        });

        if (!stoppedSession) {
          return {
            success: false,
            hasOpenedPositions: false,
          };
        }

        setSession(stoppedSession);

        return {
          success: true,
          hasOpenedPositions: false,
        };
      } catch (error) {
        return {
          success: false,
          hasOpenedPositions: true,
        };
      }
    },

    startSession: async () => {
      const { session, setSession } = get();
      const { startSession } = useSessionsStore.getState();

      if (!session) {
        return;
      }

      const startedSession = await startSession(session.sessionId ?? '');

      if (!startedSession) {
        return;
      }

      setSession(startedSession);
    },

    deleteSession: async () => {
      const { session } = get();
      const { deleteSession } = useSessionsStore.getState();

      if (!session) {
        return false;
      }

      const success = await deleteSession(session.sessionId ?? '');

      return success;
    },
  }))
);

export const useFetchSession = (sessionId: string) => {
  const data = useEditSessionStore((state) => state.session);
  const isLoading = useEditSessionStore((state) => state.isLoading);

  useEffect(() => {
    if (data?.sessionId !== sessionId) {
      useEditSessionStore.getState().fetch({ sessionId });
    }
  }, []);

  return { data, isLoading };
};

export default useEditSessionStore;
