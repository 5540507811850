import React from 'react';
import { createBrowserRouter, redirect } from 'react-router-dom';

import { Layout } from 'core/components/common';
import { Settings, Error, Reports } from 'features';

import PATHS from './paths';
import ProtectedRoute from './ProtectedRoute';
import { authRoutes, sessionRoutes } from './routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        loader: () => {
          return redirect(PATHS.sessions);
        },
      },
      authRoutes,
      sessionRoutes,
      {
        path: PATHS.settings,
        element: (
          <ProtectedRoute from="notAuthUser">
            <Settings />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.reports,
        element: (
          <ProtectedRoute from="notAuthUser">
            <Reports />
          </ProtectedRoute>
        ),
      },
      {
        path: PATHS.error,
        element: <Error />,
      },
      {
        path: '*',
        element: <Error />,
      },
    ],
  },
]);

export default router;
