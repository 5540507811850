import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'core/components/form';

import { CommonInputsProps } from './types';

const AdditionalPositionsInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${basename}.openPositionParams.additionalSteps`,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4">
          <div className="mb-4 flex flex-row items-center gap-2">
            <button
              onClick={() => remove(index)}
              disabled={disabled}
              className="h-full bg-red-500 p-2"
            >
              <XMarkIcon className="size-5" />
            </button>
            <p className="text-sm">
              {t(
                'pages.createSession.common.form.openPositionParams.additionalSteps.title',
                { number: index + 1 }
              )}
            </p>
          </div>

          <div className="flex w-full gap-3">
            <TextInput
              control={control}
              name={`${basename}.openPositionParams.additionalSteps.${index}.entrySize`}
              componentProps={{
                disabled,
                type: 'number',
                label: t(
                  'pages.createSession.common.form.openPositionParams.additionalSteps.entrySize.label',
                  { number: index + 1 }
                ),
                suffix: '$',
              }}
            />
            <TextInput
              name={`${basename}.openPositionParams.additionalSteps.${index}.triggeringPriceChange`}
              control={control}
              componentProps={{
                disabled,
                type: 'number',
                label: t(
                  'pages.createSession.common.form.openPositionParams.additionalSteps.triggeringPriceChange.label',
                  { number: index + 1 }
                ),
                suffix: '%',
              }}
            />
          </div>
        </div>
      ))}
      {fields.length < 10 && (
        <button
          type="button"
          onClick={() =>
            append({
              entrySize: undefined,
              triggeringPriceChange: undefined,
            })
          }
          className="outlined-button-s mt-2 w-full"
          disabled={disabled}
        >
          {t(
            'pages.createSession.common.form.openPositionParams.additionalSteps.add'
          )}
        </button>
      )}
    </div>
  );
};

export default AdditionalPositionsInputs;
