import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DurationInput, TextInput } from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import { CommonInputsProps } from './types';
import { CreateEditSessionFormData } from '../../validationSchema';

const TriggerInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext<CreateEditSessionFormData>();

  return (
    <DisclosureWithTitle
      title={
        <h5>{t('pages.createSession.common.form.triggerParams.title')}</h5>
      }
      defaultOpen={true}
    >
      <DurationInput
        control={control}
        name={`${basename}.triggerParams.lastPeriodInterval`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.triggerParams.lastPeriodInterval.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.triggerParams.lastPeriodPriceChange`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.triggerParams.lastPeriodPriceChange.label'
          ),
          suffix: '%',
          onChange: () => {
            trigger(`${basename}.triggerParams.priceChangeFromPrevClose`);
          },
        }}
      />
      <p className="caption text-center text-[12px]">
        {t('pages.createSession.andOr')}
      </p>
      <TextInput
        control={control}
        name={`${basename}.triggerParams.priceChangeFromPrevClose`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.triggerParams.priceChangeFromPrevClose.label'
          ),
          suffix: '%',
          onChange: () => {
            trigger(`${basename}.triggerParams.lastPeriodPriceChange`);
          },
        }}
      />
    </DisclosureWithTitle>
  );
};

export default TriggerInputs;
