import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { DOMAttributes, FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuthStore } from 'common/stores';
import { PATHS } from 'core/router';

type BurgerMenuButtonProps = {
  opened: boolean;
  onClick: DOMAttributes<HTMLButtonElement>['onClick'];
};

export const BurgerMenuButton: FC<BurgerMenuButtonProps> = ({
  opened,
  onClick,
}) => {
  return (
    <span onClick={onClick} className="-m-2.5 rounded-md p-2.5 text-gray-700">
      <span className="sr-only">{opened ? 'Open menu' : 'Close menu'}</span>
      {opened ? (
        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
      ) : (
        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
      )}
    </span>
  );
};

export type HeaderLinksProps = {
  onClick?: () => void;
};

export const HeaderLinks: FC<HeaderLinksProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const isAuth = useAuthStore((state) => state.accessToken !== null);

  if (!isAuth) return null;

  return (
    <Fragment>
      <Link to={PATHS.sessions} className="caption" onClick={onClick}>
        {t('components.header.sessions')}
      </Link>
      <Link to={PATHS.createSession} className="caption" onClick={onClick}>
        {t('components.header.newSession')}
      </Link>
      <Link to={PATHS.settings} className="caption" onClick={onClick}>
        {t('components.header.settings')}
      </Link>
      <Link to={PATHS.reports} className="caption" onClick={onClick}>
        {t('components.header.reports')}
      </Link>
    </Fragment>
  );
};

export const HeaderButtons = () => {
  const { t } = useTranslation();
  const isAuth = useAuthStore((state) => state.accessToken !== null);

  if (!isAuth) return null;

  return (
    <Fragment>
      <button onClick={useAuthStore.getState().logout}>
        {t('common.logout')}
      </button>
    </Fragment>
  );
};
