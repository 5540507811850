import { t } from 'i18next';
import * as yup from 'yup';

import { createOtpCodeSchema } from 'core/components/form';

export const pinCodeSchema = yup
  .object({
    code: createOtpCodeSchema({
      size: 4,
      name: t('pages.verifyPhone.fields.otpCode'),
    }),
  })
  .required();

export type PinCodeFormData = yup.InferType<typeof pinCodeSchema>;
