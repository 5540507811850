import { t } from 'i18next';
import React from 'react';
import { useController, FieldValues } from 'react-hook-form';
import * as yup from 'yup';

import { DefaultFormInputProps } from './types';
import { TagInput, TagInputProps } from '../uikit';

export const createTagInputSchema = ({
  name,
  required = false,
}: {
  name: string;
  required: boolean;
}) => {
  return yup
    .array()
    .of(yup.string())
    .when([], {
      is: () => required,
      then: (schema) =>
        schema.required(t('errors.validation.isRequired', { name })),
      otherwise: (schema) => schema.notRequired(),
    });
};

const TagInputController = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, TagInputProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  const onChange: TagInputProps['onChange'] = (tags) => {
    if (componentProps && componentProps.onChange) {
      componentProps.onChange(tags);
    }

    field.onChange(tags);
  };

  return (
    <TagInput
      {...field}
      error={error}
      {...componentProps}
      onChange={onChange}
    />
  );
};

export default TagInputController;
