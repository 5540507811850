import React, { FC, useCallback } from 'react';

import { Action, ActionFilter, ActionType } from 'common/models';
import { FiltersProps } from 'core/components/uikit';

import TypeSelectInput from './TypeSelectInput';
import { useFetchActionsWithTimer } from './useFetchActions';
import SearchInput from '../SearchInput';

export type LastActionsFiltersProps = FiltersProps<Action> &
  Pick<ReturnType<typeof useFetchActionsWithTimer>, 'args' | 'fetch'>;

const LastActionsFilters: FC<LastActionsFiltersProps> = ({
  setPagination,
  setExpanded,
  fetch,
  args,
}) => {
  const onChangeFiltration = useCallback(
    (newFilters: ActionFilter) => {
      fetch({
        ...args,
        ...newFilters,
        page: 1,
      });
      setPagination((old) => ({ ...old, pageIndex: 0 }));
      setExpanded({});
    },
    [args, fetch, setPagination]
  );

  const onSearch = useCallback<(search: string) => void>(
    (search) => onChangeFiltration({ search }),
    [onChangeFiltration]
  );

  const onChange = useCallback<(type: ActionType | undefined) => void>(
    (type) => onChangeFiltration({ type }),
    [onChangeFiltration]
  );

  return (
    <div className="flex flex-row gap-2">
      <SearchInput value={args?.search} onSearch={onSearch} />
      <TypeSelectInput type={args?.type} onChange={onChange} />
    </div>
  );
};

export default LastActionsFilters;
