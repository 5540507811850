import React, { FC } from 'react';
import { UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TagInput } from 'core/components/form';

import { CreateEditSessionFormData } from '../validationSchema';

export type BlacklistInputProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const BlacklistInput: FC<BlacklistInputProps> = ({ control, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 flex-col gap-2">
      <h4>{t('pages.createSession.blacklist.label')}</h4>
      <TagInput
        control={control}
        name="blacklist"
        componentProps={{
          disabled,
        }}
      />
    </div>
  );
};

export default BlacklistInput;
