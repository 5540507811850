import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PositionLocateType } from 'common/models';
import { Option } from 'common/types';
import { Select } from 'core/components/uikit';

export type LocateTypeFilterOption = Option<PositionLocateType | null>;

export type LocateTypeSelectInputProps = {
  type: string | undefined;
  onChange: (value: PositionLocateType | undefined) => void;
};

const LocateTypeSelectInput: FC<LocateTypeSelectInputProps> = ({
  type,
  onChange,
}) => {
  const { t } = useTranslation();

  const filterOptions = useMemo<LocateTypeFilterOption[]>(
    () => [
      {
        value: null,
        label: t('components.positionsTable.filters.locateType.all'),
      },
      {
        value: 'etb',
        label: t('components.positionsTable.filters.locateType.etb'),
      },
      {
        value: 'htb',
        label: t('components.positionsTable.filters.locateType.htb'),
      },
    ],
    [t]
  );

  const value =
    filterOptions.find((item) => item.value === type) ?? filterOptions[0];

  return (
    <Select
      value={value}
      label={t('components.positionsTable.filters.locateType.label')}
      className="max-w-52"
      onChange={(newValue) =>
        onChange((newValue as LocateTypeFilterOption).value ?? undefined)
      }
      options={filterOptions}
    />
  );
};

export default LocateTypeSelectInput;
