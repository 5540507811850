import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DurationInput, TextInput } from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import AdditionalPositionsInputs from './AdditionalPositionsInputs';
import OrderTypeInputs from './OrderTypeInputs';
import { CommonInputsProps } from './types';

const OpenPositionInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      title={
        <h5>{t('pages.createSession.common.form.openPositionParams.title')}</h5>
      }
      defaultOpen={true}
    >
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.maxPositionVolume`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.maxPositionVolume.label'
          ),
          suffix: '$',
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.maxEntryVolume`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.maxEntryVolume.label'
          ),
          suffix: '$',
        }}
      />
      <DurationInput
        control={control}
        name={`${basename}.openPositionParams.openEntryInterval`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.openEntryInterval.label'
          ),
        }}
      />
      <OrderTypeInputs
        {...{
          control,
          disabled,
          orderTypeName: `${basename}.openPositionParams.orderType`,
          orderPriceTypeName: `${basename}.openPositionParams.orderPriceType`,
        }}
      />
      <DurationInput
        control={control}
        name={`${basename}.openPositionParams.orderRefreshInterval`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.orderRefreshInterval.label'
          ),
        }}
      />
      <DurationInput
        control={control}
        name={`${basename}.openPositionParams.openEntryLimit`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.openEntryLimit.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.chaseDown`}
        componentProps={{
          disabled,
          type: 'number',
          label: t(
            'pages.createSession.common.form.openPositionParams.chaseDown.label'
          ),
          suffix: '%',
        }}
      />
      <AdditionalPositionsInputs {...{ control, basename, disabled }} />
    </DisclosureWithTitle>
  );
};

export default OpenPositionInputs;
