import { AxiosResponse } from 'axios';

import { OrderFilter, PaginatedResult, Order } from 'common/models';
import http from 'core/services/api';

import { ORDERS } from './mockData';

class OrdersRepository {
  getOrdersMocked = async ({
    side,
    status,
    positionId,
    page = 1,
    perPage = 10,
  }: OrderFilter) =>
    http.fetch(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      let filteredData = ORDERS;

      if (positionId) {
        filteredData = filteredData.filter(
          (position) => position.positionId === positionId
        );
      }

      if (side) {
        filteredData = filteredData.filter(
          (position) => position.side === side
        );
      }

      if (status) {
        filteredData = filteredData.filter(
          (position) => position.status === status
        );
      }

      const total = filteredData.length;
      const startIndex = (page - 1) * perPage;
      const paginatedItems = filteredData.slice(
        startIndex,
        startIndex + perPage
      );

      return {
        data: {
          total,
          filtered: paginatedItems.length,
          items: paginatedItems,
        } as PaginatedResult<Order>,
        status: 200,
        statusText: '200',
        headers: {},
        config: {},
      } as AxiosResponse<PaginatedResult<Order> | null>;
    });

  getOrders = async ({ page = 1, perPage = 10, ...params }: OrderFilter) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Order>>(`/orders/filter`, {
        signal,
        params: {
          ...params,
          perPage,
          page,
        },
      });
    });
}

export const ordersRepository = new OrdersRepository();
