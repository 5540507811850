import { Duration, Nullable } from 'common/types';

export const TICKER_TYPE = {
  WATCHLIST: 'watchlist',
  PRE_WATCHLIST: 'preWatchlist',
} as const;
export type TickerType = (typeof TICKER_TYPE)[keyof typeof TICKER_TYPE];

export const MARKET_TYPE = {
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
} as const;
export type MarketType = (typeof MARKET_TYPE)[keyof typeof MARKET_TYPE];

export type TickerTriggersData = Nullable<{
  updatedAt: Date;
  prevCloseTrigger: number;
  lastPeriodTrigger: number;
}>;

export type TickerPrice = Nullable<{
  lastPrice: number;
  bidPrice: number;
  askPrice: number;
}>;

export type TickerFiltersData = Nullable<{
  createdAt: Date;
  updatedAt: Date;
  fromWhitelist: boolean;
  marketCap: number;
  price: TickerPrice;
  priceChangeFromPrevClose: number;
  currentDayVolume: number;
  lastPeriodVolume: number;
  lastPeriod: Duration;
}>;

export type Ticker = Nullable<{
  id: string;
  listType: TickerType;
  ticker: string;
  sessionId: string;
  marketType: MarketType;
  filtersData: TickerFiltersData;
  triggersData: TickerTriggersData;
}>;

export type SessionTickers = Nullable<{
  ahMarket: Ticker[];
  phMarket: Ticker[];
  rthMarket: Ticker[];
}>;
