import React, { FC } from 'react';
import { UseControllerProps, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  formatedTimeToMinutesFromStartOfDay,
  minutesFromStartOfDayToFormatedTime,
} from 'common/utils';
import { CheckboxInput, RangeInput } from 'core/components/form';

import CommonInputs from './CommonInputs';
import { CreateEditSessionFormData } from '../validationSchema';

export type PrehoursParametersProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
};

const PrehoursParameters: FC<PrehoursParametersProps> = ({
  control,
  disabled,
}) => {
  const { t } = useTranslation();
  const isActive = useWatch({
    control,
    name: 'phParams.active',
  });
  const formDisabled = disabled || !isActive;

  return (
    <div className="flex flex-1 flex-col gap-3">
      <div className="flex items-center gap-3">
        <h4>{t('pages.createSession.prehours.title')}</h4>
        <CheckboxInput
          control={control}
          name="phParams.active"
          componentProps={{ disabled }}
        />
      </div>
      <div className={`flex flex-col gap-3 ${isActive ? '' : 'hidden'}`}>
        <RangeInput
          componentProps={{
            label: t(
              'pages.createSession.prehours.form.marketHoursRange.label'
            ),
            min: '4:00 AM',
            max: '9:30 AM',
            step: 30,
            disabled: formDisabled,
            renderLabel: minutesFromStartOfDayToFormatedTime,
            parseValue: formatedTimeToMinutesFromStartOfDay,
            formatValue: minutesFromStartOfDayToFormatedTime,
          }}
          control={control}
          name="phParams.marketHoursRange"
        />
        <CommonInputs
          control={control}
          disabled={formDisabled}
          basename="phParams"
        />
      </div>
    </div>
  );
};

export default PrehoursParameters;
