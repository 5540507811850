import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'core/components/uikit';

interface SearchInputProps {
  onSearch: (query: string) => void;
  placeholder?: string;
  value?: string;
}

const SearchInput: FC<SearchInputProps> = ({
  onSearch,
  value = '',
  placeholder,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (inputRef.current && value !== '') {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      onSearch(newValue);
    }, 300);
  };

  return (
    <TextInput
      type="search"
      ref={inputRef}
      defaultValue={value}
      className="max-w-52"
      onChange={handleChange}
      label={placeholder ?? t('common.search')}
      placeholder={placeholder ?? t('common.search')}
    />
  );
};

export default SearchInput;
