import { Field, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { FC } from 'react';
import OtpInput, { OTPInputProps } from 'react-otp-input';

import DefaultInput from './DefaultInput';
import { InputProps } from './types';

export type CodeInputProps = InputProps &
  Omit<OTPInputProps, 'renderInput'> & { disabled?: boolean };

const CodeInput: FC<CodeInputProps> = ({
  error,
  label,
  disabled,
  className,
  ...otpInputProps
}) => {
  return (
    <Field
      className={clsx('flex w-full flex-col', className)}
      disabled={disabled}
    >
      {label && <Label className="caption mb-1 ml-1 text-xs">{label}</Label>}
      <OtpInput
        numInputs={6}
        placeholder="000000"
        containerStyle="mb-4 flex justify-between gap-2"
        inputType="number"
        {...otpInputProps}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderInput={({ style, ...otherProps }, index) => (
          <DefaultInput
            {...otherProps}
            disabled={disabled}
            autoFocus={index === 0}
            className="max-w-14 flex-1 text-center caret-transparent"
          />
        )}
      />
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </Field>
  );
};

export default CodeInput;
