import React, { FC, PropsWithChildren, JSX, HTMLAttributes } from 'react';

export type WrapperProps = HTMLAttributes<HTMLOrSVGElement> & {
  as?: keyof JSX.IntrinsicElements;
  internalClassName?: string;
};

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({
  as: Tag = 'div',
  children,
  className,
  internalClassName = '',
  ...rest
}) => {
  return (
    <Tag {...rest} className={`h-full p-block ${className ? className : ''}`}>
      <div className={`ml-auto mr-auto max-w-[1920px] ${internalClassName}`}>
        {children}
      </div>
    </Tag>
  );
};

export default Wrapper;
