import { t } from 'i18next';
import React, { ChangeEventHandler } from 'react';
import { useController, FieldValues } from 'react-hook-form';
import * as yup from 'yup';

import { DefaultFormInputProps } from './types';
import { TextInput, TextInputProps } from '../uikit';

const TIME_REGEX = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/;
export const createTimeInputSchema = ({
  name,
  required,
}: {
  name: string;
  required: boolean;
}) => {
  return yup
    .string()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return originalValue.trim() === '' ? null : value;
      }
      return value;
    })
    .when([], {
      is: () => required,
      then: (schema) =>
        schema
          .required(t('errors.validation.isRequired', { name }))
          .matches(TIME_REGEX, t('errors.validation.time')),
      otherwise: (schema) =>
        schema
          .nullable()
          .notRequired()
          .matches(TIME_REGEX, t('errors.validation.time')),
    });
};

export const createNumberInputSchema = ({
  min,
  max,
  name,
  required,
}: {
  min: number;
  max: number;
  name: string;
  required: boolean;
}) =>
  yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        return originalValue.trim() === '' ? null : value;
      }
      return value;
    })
    .when([], {
      is: () => required,
      then: (schema) =>
        schema
          .required(t('errors.validation.isRequired', { name }))
          .min(min, t('errors.validation.minLength', { name, length: min }))
          .max(
            max,
            t('errors.validation.cantBeGreater', { name, length: max })
          ),
      otherwise: (schema) =>
        schema
          .nullable()
          .notRequired()
          .min(min, t('errors.validation.minLength', { name, length: min }))
          .max(
            max,
            t('errors.validation.cantBeGreater', { name, length: max })
          ),
    })
    .typeError(t('errors.validation.mustBe', { name, type: 'number' }));

const TextInputController = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DefaultFormInputProps<FormValues, TextInputProps>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    field.onChange(e);

    if (componentProps && componentProps.onChange) {
      componentProps.onChange(e);
    }
  };

  return (
    <TextInput
      {...field}
      error={error}
      {...componentProps}
      onChange={onChange}
    />
  );
};

export default TextInputController;
