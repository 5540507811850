import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CodeInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';

import { PinCodeFormData, pinCodeSchema } from './validationSchema';

export type PinCodeFormProps = {
  onSubimit: (values: PinCodeFormData) => Promise<void>;
};

const PinCodeForm: FC<PinCodeFormProps> = ({ onSubimit }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<PinCodeFormData>({
    resolver: yupResolver(pinCodeSchema),
    mode: 'all',
    defaultValues: { code: '' },
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit(onSubimit);

  return (
    <form className="flex flex-col" onSubmit={onSubmit}>
      <CodeInput
        name="code"
        control={control}
        componentProps={{ numInputs: 4 }}
      />
      <button type="submit" className="mb-4" disabled={isButtonDisabled}>
        {isSubmitting ? <CircularIndicator /> : t('pages.pinCodeForm.action')}
      </button>
      <p className="text-center text-[12px]">
        {t('pages.pinCodeForm.helperText')}
      </p>
    </form>
  );
};

export default PinCodeForm;
