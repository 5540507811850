import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Position,
  PositionFilter,
  PositionLocateType,
  PositionStatus,
} from 'common/models';
import { FiltersProps } from 'core/components/uikit';

import LocateTypeSelectInput from './LocateTypeSelectInput';
import StatusSelectInput from './StatusSelectInput';
import { useFetchPositions } from './useFetchPositions';
import SearchInput from '../SearchInput';

export type PositionFiltersFiltersProps = FiltersProps<Position> &
  Pick<ReturnType<typeof useFetchPositions>, 'args' | 'fetch'>;

const PositionFilters: FC<PositionFiltersFiltersProps> = ({
  setPagination,
  setExpanded,
  fetch,
  args,
}) => {
  const { t } = useTranslation();
  const onChangeFiltration = useCallback(
    (newFilters: PositionFilter) => {
      fetch({
        ...args,
        ...newFilters,
        page: 1,
      });
      setPagination((old) => ({ ...old, pageIndex: 0 }));
      setExpanded({});
    },
    [args, fetch, setPagination]
  );

  const onSearchByTicker = useCallback<(ticker: string) => void>(
    (ticker) => onChangeFiltration({ ticker }),
    [onChangeFiltration]
  );

  const onFilterByStatus = useCallback<
    (status: PositionStatus | undefined) => void
  >((status) => onChangeFiltration({ status }), [onChangeFiltration]);

  const onFilterByLocateType = useCallback<
    (locateType: PositionLocateType | undefined) => void
  >((locateType) => onChangeFiltration({ locateType }), [onChangeFiltration]);

  return (
    <div className="flex flex-row gap-2">
      <SearchInput
        value={args?.ticker}
        onSearch={onSearchByTicker}
        placeholder={t('components.positionsTable.filters.ticker')}
      />
      <StatusSelectInput type={args?.status} onChange={onFilterByStatus} />
      <LocateTypeSelectInput
        type={args?.locateType}
        onChange={onFilterByLocateType}
      />
    </div>
  );
};

export default PositionFilters;
