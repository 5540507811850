import {
  Dialog,
  DialogPanel,
  DialogBackdrop,
  DialogTitle,
  Description,
} from '@headlessui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AsyncCallback, Callback } from 'common/types';

import PinCodeForm, { PinCodeFormProps } from '../PinCodeForm';

export type ConfirmActionDialogProps = {
  title?: string;
  isOpen: boolean;
  subtitle?: string;
  onClose: Callback;
  onConfirm: AsyncCallback;
};

const ConfirmActionDialog: FC<ConfirmActionDialogProps> = ({
  title,
  isOpen,
  onClose,
  subtitle,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const onSubmit: PinCodeFormProps['onSubimit'] = async ({ code }) => {
    console.log({ code });

    await onConfirm();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg space-y-4 rounded-md bg-white p-12">
          <DialogTitle className="mb-8 text-center">
            {title ?? t('components.confirmActionDialog.title')}
          </DialogTitle>
          {subtitle && (
            <Description className="text-center">{subtitle}</Description>
          )}
          <PinCodeForm onSubimit={onSubmit} />
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ConfirmActionDialog;
