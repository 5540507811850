import { ColumnDef } from '@tanstack/react-table';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Ticker } from 'common/models';
import {
  Table,
  enumCell,
  dateCell,
  valueCell,
  numberCell,
} from 'core/components/uikit';

export type TickerTableProps = {
  data: Ticker[];
};

const TickerTable: FC<TickerTableProps> = ({ data }) => {
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<Ticker>[]>(
    () => [
      {
        header: t('components.tickersTable.headers.ticker'),
        accessorKey: 'ticker',
        cell: valueCell(),
      },
      {
        header: t('components.tickersTable.headers.marketCap'),
        accessorKey: 'filtersData.marketCap',
        cell: numberCell({
          prefix: '$',
          handler: (value) =>
            value ? `${(value / 1000000).toFixed(2)}M` : null,
        }),
      },
      {
        header: t('components.tickersTable.headers.listType'),
        accessorKey: 'listType',
        cell: enumCell(),
      },
      {
        header: t('components.tickersTable.headers.prevCloseTrigger'),
        accessorKey: 'triggersData.prevCloseTrigger',
        cell: numberCell({
          suffix: '%',
          handler: (value) => (value ? (value * 100).toFixed(2) : null),
        }),
      },
      {
        header: t('components.tickersTable.headers.lastPeriodTrigger'),
        accessorKey: 'triggersData.lastPeriodTrigger',
        cell: numberCell({
          suffix: '%',
          handler: (value) => (value ? (value * 100).toFixed(2) : null),
        }),
      },
      {
        header: t('components.tickersTable.headers.currentDayVolume'),
        accessorKey: 'filtersData.currentDayVolume',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.lastPrice'),
        accessorKey: 'filtersData.price.lastPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.bidPrice'),
        accessorKey: 'filtersData.price.bidPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.askPrice'),
        accessorKey: 'filtersData.price.askPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.lastPeriodVolume'),
        accessorKey: 'filtersData.lastPeriodVolume',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.tickersTable.headers.lastPeriodMinutes'),
        accessorKey: 'filtersData.lastPeriod',
        cell: valueCell(),
      },
      {
        header: t('components.tickersTable.headers.priceChangePercent'),
        accessorKey: 'triggersData.priceChangeFromPrevClose',
        cell: numberCell({ suffix: '%' }),
      },
      {
        header: t('components.tickersTable.headers.updatedAt'),
        accessorKey: 'triggersData.updatedAt',
        cell: dateCell(),
      },
    ],
    [t]
  );

  return (
    <Table
      data={data}
      columns={columns}
      totalCount={data.length}
      options={{ manualPagination: false }}
    />
  );
};

export default TickerTable;
