import { t } from 'i18next';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Option } from 'common/types';

import { DefaultFormInputProps } from './types';
import { TextInput, Select, SelectProps } from '../uikit';

export const createDurationInputSchema = ({
  name,
  required = false,
}: {
  name: string;
  required: boolean;
}) =>
  yup
    .string()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .when([], {
      is: () => required,
      then: (schema) =>
        schema.required(t('errors.validation.isRequired', { name })),
      otherwise: (schema) => schema.nullable().notRequired(),
    })
    .matches(
      /^$|^[1-9]\d*(s|m|h|d)$/,
      t('errors.validation.isInvalid', { name })
    );

export type DurationInputProps<FormValues extends FieldValues> =
  DefaultFormInputProps<
    FormValues,
    {
      disabled?: boolean;
      label?: string;
    }
  >;

const DurationInput = <FormValues extends FieldValues>({
  componentProps,
  ...input
}: DurationInputProps<FormValues>) => {
  const { t } = useTranslation();
  const { field, fieldState } = useController(input);
  const { onChange, value = '' } = field;
  const error = fieldState.error?.message;
  const [unit, setUnit] = useState(() => value.slice(-1) || 's');

  const options: Option[] = useMemo(
    () => [
      { label: t('components.durationInput.seconds'), value: 's' },
      { label: t('components.durationInput.minutes'), value: 'm' },
      { label: t('components.durationInput.hours'), value: 'h' },
      { label: t('components.durationInput.days'), value: 'd' },
    ],
    [t]
  );

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numericPart = e.target.value.replace(/\D/g, '');
    const updatedValue = numericPart ? `${numericPart}${unit}` : '';
    onChange(updatedValue);
  };

  const handleSelectChange: SelectProps['onChange'] = (newValue) => {
    const unit = (newValue as Option).value;
    const numericPart = value.replace(/\D/g, '') || '';
    const updatedValue = numericPart ? `${numericPart}${unit}` : '';
    setUnit(unit);
    onChange(updatedValue);
  };

  const textValue = value.replace(/\D/g, '');
  const unitValue = useMemo(() => {
    return options.find((item) => item.value === unit) ?? options[0];
  }, [unit, options]);
  const { disabled, label } = componentProps ?? {};

  return (
    <div>
      <div className="flex flex-row items-end gap-3">
        <TextInput
          value={textValue}
          onChange={handleTextChange}
          type="number"
          disabled={disabled}
          label={label}
        />
        <Select
          value={unitValue}
          onChange={handleSelectChange}
          options={options}
          disabled={disabled}
        />
      </div>
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </div>
  );
};

export default DurationInput;
