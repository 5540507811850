import { DialogPanel, DialogTitle, Description } from '@headlessui/react';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StopBehavior } from 'common/models';
import { Callback } from 'common/types';
import { CircularIndicator } from 'core/components/uikit';

import useEditSessionStore from '../editSessionStore';

type StopSessionContentProps = {
  onClose: Callback;
  stopBehavior: StopBehavior;
  setStopBehavior: Dispatch<SetStateAction<StopBehavior | undefined>>;
};

const StopSessionContent: FC<StopSessionContentProps> = ({
  onClose,
  stopBehavior,
  setStopBehavior,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const onStop = async () => {
    setIsLoading(true);
    await useEditSessionStore.getState().stopSession({ stopBehavior });
    setIsLoading(false);
    onClose();
  };

  return (
    <DialogPanel className="max-w-lg rounded-md bg-white p-12">
      <DialogTitle className="mb-4 text-center text-red-700">
        {t('pages.editSession.stopDialog.secondStep.title')}
      </DialogTitle>
      <Description className="text-center">
        {t(
          stopBehavior == 'none'
            ? 'pages.editSession.stopDialog.secondStep.subtitle.stopWithoutCanceling'
            : 'pages.editSession.stopDialog.secondStep.subtitle.cancelOrders'
        )}
      </Description>
      <div className="mt-8 flex flex-col gap-2">
        <button onClick={onStop} disabled={isLoading}>
          {isLoading ? <CircularIndicator /> : t('common.yes')}
        </button>
        <button
          onClick={() => setStopBehavior(undefined)}
          className="outlined-button mt-2"
          disabled={isLoading}
        >
          {t('common.cancel')}
        </button>
      </div>
    </DialogPanel>
  );
};

export default StopSessionContent;
