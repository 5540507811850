import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PositionStatus } from 'common/models';
import { Option } from 'common/types';
import { Select } from 'core/components/uikit';

export type StatusFilterOption = Option<PositionStatus | null>;

export type StatusSelectInputProps = {
  type: string | undefined;
  onChange: (value: PositionStatus | undefined) => void;
};

const StatusSelectInput: FC<StatusSelectInputProps> = ({ type, onChange }) => {
  const { t } = useTranslation();

  const filterOptions = useMemo<StatusFilterOption[]>(
    () => [
      {
        value: null,
        label: t('components.positionsTable.filters.status.all'),
      },
      {
        value: 'open',
        label: t('components.positionsTable.filters.status.open'),
      },
      {
        value: 'closed',
        label: t('components.positionsTable.filters.status.closed'),
      },
      {
        value: 'pending',
        label: t('components.positionsTable.filters.status.pending'),
      },
    ],
    [t]
  );

  const value =
    filterOptions.find((item) => item.value === type) ?? filterOptions[0];

  return (
    <Select
      value={value}
      className="max-w-52"
      label={t('components.positionsTable.filters.status.label')}
      onChange={(newValue) =>
        onChange((newValue as StatusFilterOption).value ?? undefined)
      }
      options={filterOptions}
    />
  );
};

export default StatusSelectInput;
