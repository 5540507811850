import axios, { AxiosInstance, AxiosError } from 'axios';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

import { useAuthStore } from 'common/stores';
import i18n from 'core/lang';

import { ApiCall, ApiResponse } from './types';

class Http {
  _axios: AxiosInstance;
  _controller = new AbortController();

  get axios() {
    return this._axios;
  }

  constructor() {
    this._axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 10000,
    });

    this._axios.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        const res = error.response;
        const status = res?.status;

        if (status === 401) {
          useAuthStore.getState().refresh();
        }

        return Promise.reject(error);
      }
    );
  }

  setAuthTokenToHeaders(token: string): void {
    this._axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  clearAuthTokenFromHeaders(): void {
    this._axios.defaults.headers.common['Authorization'] = '';
  }

  async fetch<ReturnType>(
    apiCall: ApiCall<ReturnType | null>,
    showError: boolean = true,
    throwError: boolean = false
  ): Promise<ApiResponse<ReturnType | null>> {
    try {
      const { data } = await apiCall(this._axios, this._controller.signal);

      return { data, error: null };
    } catch (error) {
      if (throwError) {
        throw error;
      }

      let message = i18n.t('errors.api.other');
      let err: AxiosError<ReturnType> = { message } as AxiosError<ReturnType>;

      if (error instanceof AxiosError) {
        const res = error.response;
        try {
          message =
            res?.data?.message ||
            (res?.data?.messages as string[])[0] ||
            error.message ||
            t('errors.api.other');
        } catch (error) {
          message = t('errors.api.other');
        }
        err = error;
      }

      if (showError) {
        enqueueSnackbar(message, {
          variant: 'error',
          hideIconVariant: true,
        });
      }

      return {
        data: null,
        error: err,
      };
    }
  }
}

const http = new Http();

export default http;
