import { Input } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import { InputProps } from './types';

export type DefaultInputProps = Pick<InputProps, 'className'> &
  InputHTMLAttributes<HTMLInputElement> & {
    renderIcon?: () => ReactNode;
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

// eslint-disable-next-line react/display-name
const DefaultInput = forwardRef<HTMLInputElement, DefaultInputProps>(
  ({ className, renderIcon, prefix, suffix, ...rest }, ref) => {
    return (
      <div className="relative flex items-center">
        {prefix && (
          <div className="absolute left-2 text-slate-500">{prefix}</div>
        )}
        <Input
          ref={ref}
          onWheel={(e) => e.currentTarget.blur()}
          {...rest}
          className={clsx(
            'w-full rounded-md border-[1px] border-slate-400 bg-slate-100 px-2 py-2 text-base focus:border-blue-500 focus:outline-none disabled:bg-slate-200',
            prefix && 'pl-10',
            suffix && 'pr-10',
            className
          )}
        />
        {renderIcon ? (
          <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
            {renderIcon()}
          </div>
        ) : suffix ? (
          <div className="absolute right-2 text-slate-500">{suffix}</div>
        ) : null}
      </div>
    );
  }
);

export default DefaultInput;
