import { clsx } from 'clsx';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
} from 'features/components';

import StopSessionDialog from './StopSessionDialog';
import useSessionDetailsStore from '../editSessionStore';

export type StartStopButtonProps = {
  disabled: boolean;
};

const StartStopButton: FC<
  WithConfirmActionDialogSharedProps & StartStopButtonProps
> = ({ disabled, showConfirmActionDialog }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isStarted = useSessionDetailsStore(
    (state) => state.session?.status === 'active'
  );

  const handleClose = () => setIsOpen(false);

  const toggleSession = async () => {
    const store = useSessionDetailsStore.getState();

    if (isStarted) {
      const { hasOpenedPositions } = await store.stopSession();

      if (hasOpenedPositions) {
        setIsOpen(true);
      }
    } else {
      await store.startSession();
    }
  };

  const handleOnClick = () =>
    showConfirmActionDialog({
      onConfirm: toggleSession,
      title: t('pages.editSession.confrimDialog.title', {
        action: t(isStarted ? 'common.stop' : 'common.start').toLowerCase(),
      }),
    });

  return (
    <Fragment>
      <button
        onClick={handleOnClick}
        disabled={disabled}
        className={clsx(isStarted ? 'bg-yellow-500' : 'bg-green-500')}
      >
        {t(isStarted ? 'common.stop' : 'common.start')}
      </button>
      <StopSessionDialog isOpen={isOpen} onClose={handleClose} />
    </Fragment>
  );
};

const StartStopButtonWithHoc = withConfirmActionDialog(StartStopButton);

export default StartStopButtonWithHoc;
